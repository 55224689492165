<template>
  <div class="shipping-invoices">
    <h2 class="shipping-invoices__title ui-kit__heading">
      Транспортные накладные
    </h2>
    <custom-button
      v-if="!isOperator && isShipper"
      :disabled="disabledAddTn"
      flat
      color="primary"
      class="shipping-invoices__add"
      @click="$emit('toggle-form', true)"
    >
      <icon size="16" name="addCircleOutline" />
      Добавить
    </custom-button>
  </div>
</template>

<script>
export default {
  name: 'ShippingInvoicesHeader',
  props: {
    isOperator: Boolean,
    isShipper: Boolean,
    disabledAddTn: Boolean
  }
};
</script>

<style lang="scss">
@import '~common/assets/styles/variables.scss';

.shipping-invoices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    @media screen and (max-width: breakpoint(sm)) {
      margin: 8px 0 0 12px;
    }
  }

  &__add {
    @media screen and (max-width: breakpoint(sm)) {
      margin: 0 0 8px 12px;
    }
  }
}
</style>
